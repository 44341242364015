export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "exchangeToBUSD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exchange NECO to BUSD"])},
        "exchangeToNewNECO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exchange Old NECO to New NECO"])},
        "endTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Time"])},
        "network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Network"])},
        "necoAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NECO Amount"])},
        "oldNECO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Old NECO"])},
        "newNECO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New NECO:"])},
        "approveNECO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approve NECO"])},
        "exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exchange"])}
      },
      "zh": {
        "exchangeToBUSD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NECO 兑换 BUSD"])},
        "exchangeToNewNECO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["旧 NECO 兑换新 NECO"])},
        "endTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["结束时间"])},
        "network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["网络"])},
        "necoAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NECO 数量"])},
        "oldNECO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["旧 NECO"])},
        "newNECO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新 NECO:"])},
        "approveNECO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["授权 NECO"])},
        "exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交换"])}
      }
    }
  })
}
