import Web3 from 'web3';
import { Contract } from 'web3-eth-contract';
import NecoToNeco from '../abis/neco-to-neco';

export function getNecoToNecoAddress(chainId: number): string | undefined {
  return NecoToNeco.getNecoToNecoAddress(chainId);
}

export function getNecoToNecoContract(web3: Web3 | null, chainId: number): Contract | null {
  const address = NecoToNeco.getNecoToNecoAddress(chainId);
  if (web3 === null) {
    return null;
  }
  return new web3.eth.Contract(NecoToNeco.ABI, address);
}

export async function swapNecoToNewNeco(contract: Contract, account?: string): Promise<boolean> {
  try {
    await contract.methods.swap().send({ from: account });
    return true;
  } catch (err) {
    return false;
  }
}
