import Web3 from 'web3';
import { Contract } from 'web3-eth-contract';
import NecoToBusd from '../abis/neco-to-busd';

export function getNecoToBusdAddress(chainId: number): string | undefined {
  return NecoToBusd.getNecoToBusdAddress(chainId);
}

export function getNecoToBusdContract(web3: Web3 | null, chainId: number): Contract | null {
  const address = NecoToBusd.getNecoToBusdAddress(chainId);
  if (web3 === null) {
    return null;
  }
  return new web3.eth.Contract(NecoToBusd.ABI, address);
}

export async function swapNecoToBusd(contract: Contract, account?: string): Promise<boolean> {
  try {
    await contract.methods.swap().send({ from: account });
    return true;
  } catch (err) {
    return false;
  }
}
